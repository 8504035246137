<template>
    <div>
        <van-grid :gutter="10" :border="false" :column-num="3" class="video-list">
<!--            <van-grid-item  v-for="item in courseType" :key="item.id" :item="item"  :to="{name: 'courseList', params: { userId: item.title_rich_text }}" >-->
<!--                <van-image :src="picUrl()+item.courseImgUrl" />-->
<!--                <span>{{item.courseName}}</span>-->
<!--            </van-grid-item>-->
          <van-grid-item  v-for="item in linkArr" :key="item.id" :to="{name: 'linkPage', params: { url: item.courseLink}}">
              <van-image :src="item.courseImgUrl" />
              <span>{{item.courseName}}</span>
          </van-grid-item>

            <!-- <van-grid-item to="/videoList">
                <van-image src="https://img01.yzcdn.cn/vant/apple-2.jpg" />

                <span>钢筋扫描仪</span>
            </van-grid-item>
            <van-grid-item to="/videoList">
                <van-image src="https://img01.yzcdn.cn/vant/apple-3.jpg" />
                <span>钢筋扫描仪</span>
            </van-grid-item> -->
        </van-grid>
    </div>
</template>
<script>
    import api from '@/utils/api';
    export default {
        data() {
            return {
                courseType: [ ],
                linkArr:[
                  {
                    "id": 1,
                    "courseName": "华测系列",
                    "courseLink":'http://maplays.com/jszc/jxsp/hc/?biaos=%E5%8D%8E%E6%B5%8B',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/hc.png"
                    "courseImgUrl": require('@/assets/img/video/hc.png')
                  },
                  {
                    "id": 2,
                    "courseName": "中海达系列",
                    "courseLink":'http://maplays.com/jszc/jxsp/zhd/?biaos=%E4%B8%AD%E6%B5%B7%E8%BE%BE',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/zhd.png"
                    "courseImgUrl": require('../../../assets/img/video/zhd.png')
                  },
                  {
                    "id": 3,
                    "courseName": "合众思壮系列",
                    "courseLink":'http://maplays.com/jszc/jxsp/ufo/?biaos=UFO',
                    "courseImgUrl": require('../../../assets/img/video/ufooo.png')
                  },
                  {
                    "id": 4,
                    "courseName": "CORS账号",
                    "courseLink":'http://maplays.com/jszc/jxsp/cors/?biaos=cors%E8%B4%A6%E5%8F%B7',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/zw.png"
                    "courseImgUrl": require('../../../assets/img/video/corsss.png')
                  },
                  {
                    "id": 5,
                    "courseName": "测绘玩家",
                    "courseLink":'http://maplays.com/jszc/jxsp/sn/?biaos=%E6%B5%8B%E7%BB%98%E7%8E%A9%E5%AE%B6',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/qx.png"
                    "courseImgUrl": require('../../../assets/img/video/cgwjj.png')
                  },
                  {
                    "id": 6,
                    "courseName": "南方系列",
                    "courseLink":'http://maplays.com/jszc/jxsp/nf/?biaos=%E5%8D%97%E6%96%B9',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/nf.png"
                    "courseImgUrl": require('../../../assets/img/video/nfff.png')
                  },
                  {
                    "id": 7,
                    "courseName": "全站仪系列",
                    "courseLink":'http://maplays.com/jszc/jcsz/zhd/?biaos=%E5%85%A8%E7%AB%99%E4%BB%AA',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/nf.png"
                    "courseImgUrl": require('../../../assets/img/video/qzyyy.png')
                  },
                  {
                    "id": 8,
                    "courseName": "水准仪系列",
                    "courseLink":'http://maplays.com/jszc/jcsz/hc/?biaos=%E6%B0%B4%E5%87%86%E4%BB%AA',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/nf.png"
                    "courseImgUrl": require('../../../assets/img/video/szyyy.png')
                  },
                  {
                    "id": 9,
                    "courseName": "CASS软件",
                    "courseLink":'http://maplays.com/jszc/jcsz/nf/?biaos=CASS%E8%BD%AF%E4%BB%B6',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/nf.png"
                    "courseImgUrl": require('../../../assets/img/video/cass.png')
                  },
                  {
                    "id": 10,
                    "courseName": "道路工程",
                    "courseLink":'http://maplays.com/jszc/jcsz/ufo/?biaos=%E9%81%93%E8%B7%AF%E5%B7%A5%E7%A8%8B',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/nf.png"
                    "courseImgUrl": require('../../../assets/img/video/dlgcc.png')
                  },
                  {
                    "id": 11,
                    "courseName": "飞时达",
                    "courseLink":'http://www.maplays.com/jszc/jcsz/cors/?biaos=%E9%A3%9E%E6%97%B6%E8%BE%BE',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/nf.png"
                    "courseImgUrl": require('../../../assets/img/video/dlfsd.png')
                  },
                  {
                    "id": 12,
                    "courseName": "无人机",
                    "courseLink":'http://maplays.com/jszc/jcsz/wrj/?biaos=%E6%97%A0%E4%BA%BA%E6%9C%BA',
                    // "courseImgUrl": "http://maplays.net/mobile/pic/nf.png"
                    "courseImgUrl": require('../../../assets/img/video/wrjj.png')
                  }
                ]
            };
        },
        created () {
            this.loadMapData()
        },
        methods:{
            //加载视频教程系列
            async loadMapData () {
                const { data } = await api.videoList();
                // console.log(data);
                this.courseType=data;
            },
            //返回图片路径
            picUrl(){
                return "http://maplays.net";
            }
        }
    }
</script>
<style lang="less" scoped>
    .lina{
      color:#323233 ;
      text-align: center;
      display: inline-grid;
    }
    .video-list span {
        font-size: 26px;
        margin-top: 20px;
        height: 76px;
    }
    .van-grid-item /deep/ .van-grid-item__content {
        padding-bottom: 0;
        border-radius: 10px!important;
    }
</style>
